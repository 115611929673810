<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-05 10:30:26
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-14 11:20:41
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/models/DataRank/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-spin :spinning="isLoading" wrapperClassName="spin-wrapper">
    <div class="data-rank-container">
      <div class="head">
        <div class="left">
          <a-dropdown :trigger="['click']" overlayClassName="select-type-dropdown">
            <div class="current-type">
              <HomeTitle class="title" title="数据排行" />
              <Arrow class="icon"/>
            </div>
            <a-menu slot="overlay" @click="handleSelectType">
              <a-menu-item :class="{active: item.id === currentType}" v-for="item in typeList" :key="item.id" style="font-size: 1em">
                {{ item.name }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="right">
          <div class="search-item">
            <a-range-picker
              format="MM.DD"
              :placeholder="['Start', 'End']"
              @change="ChangeDate"
              :value="rangePickerDate"
              :allowClear="false"
              :ranges="{
                今日: [initDate(0, 0).start, initDate(0, 0).end],
                昨日: [initDate(1, 1).start, initDate(1, 1).end],
                最近一周: [initDate(6, 0).start, initDate(6, 0).end],
                最近一个月: [initDate(30, 0).start, initDate(30, 0).end],
                上一个月: getLastMonthDuration()
              }"
              :disabledDate="disabledDate"
            ></a-range-picker>
          </div>
          <div class="search-item target">
            <a-select v-model="searchquery.code" @change="handleChangeCode" style="width: 100%">
              <template v-for="item in targetList">
                <a-select-option v-if="showSelectItem(item)" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
              </template>
            </a-select>
          </div>
        </div>
      </div>
      <div class="main">
        <DataChart :target="searchquery.code" :type="currentType" ref="chart" v-if="currentType !== 'row'"/>
        <DataRow :target="searchquery.code" ref="row" v-else/>
      </div>
      <!-- <div>是否是固定位：{{ isFixed }}</div>
      <div v-if="!isFixed">第{{ rowIndex }}行</div>
      <div v-if="!isFixed">第{{ colIndex }}列</div>
      <div>{{ currentType }}</div>
      <div>{{ searchquery.code }}</div>
      <div>{{ searchquery }}</div> -->
    </div>
  </a-spin>
</template>

<script>
import HomeTitle from '../../components/homeTitle.vue'
import Arrow from '@/assets/icons/arrow.svg?inline'
import { typeList, targetList } from './typeList'
import { mapState } from 'vuex'
// import { settingLayout } from '@/apiForManage/dashboard'
import { settingLayout, getTrafficPlatData } from '@/apiForManage/dashboard'
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
import DataChart from './DataChart.vue'
import DataRow from './DataRow.vue'
export default {
  name: 'DataRank',
  components: { HomeTitle, Arrow, DataChart, DataRow },
  mixins: [mixDate],
  data () {
    return {
      typeList,
      targetList,
      currentType: undefined,
      isLoading: false,
      searchquery: {
        startDate: undefined,
        endDate: undefined,
        code: undefined
      },
      fetchData: {}
    }
  },
    computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      dashboardLayout: (state) => state.user.dashboardLayoutForMergex,
      chartInfo: (state) => state.user.dashboardLayoutForMergex.chartInfo,
      adminUserId: (state) => state.user.adminUserId,
      chartInfoList: (state) => state.user.dashboardLayoutForMergex.chartInfoList
    })
  },
  props: {
    // 行
    rowIndex: {
      type: Number,
      default: 0
    },
    // 列
    colIndex: {
      type: Number,
      default: 0
    },
    // 是否是固定位
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  created () {
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.handleInit()
    this.handleSearch()
  },
  methods: {
    showSelectItem (item) {
      if (item.show === 0) {
        return true
      } else if ((this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) && item.show === 1) {
        return true
      } else if (this.role !== 'SUPER_ADMIN' && +this.adminUserId !== 1 && item.show === 2) {
        return true
      } else {
        return false
      }
    },
    async handleChangeCode () {
      if (this.isFixed) {
        // 固定位
        const chartInfo = JSON.parse(JSON.stringify(this.chartInfo))
        chartInfo.target = this.searchquery.code
        await this.handleSettingLayout(chartInfo, this.chartInfoList)
      } else {
        // 非固定位
        const chartInfoList = JSON.parse(JSON.stringify(this.chartInfoList))
        chartInfoList[this.rowIndex][this.colIndex].target = this.searchquery.code
        await this.handleSettingLayout(this.chartInfo, chartInfoList)
      }
      this.handleSearch()
    },
    async handleSearch () {
      try {
        this.isLoading = true
        const { data = {} } = await getTrafficPlatData(this.searchquery)
        this.fetchData = data
        this.handleDealData()
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleDealData () {
      this.$nextTick(() => {
        if (this.currentType === 'row') {
          // 列表处理
          this.$refs.row && this.$refs.row.handleDealData(this.fetchData)
        } else {
          // 图表处理
          this.$refs.chart && this.$refs.chart.handleDealData(this.fetchData, this.currentType)
        }
      })
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: date[1].format('YYYY-MM-DD'),
        dateStart: date[0].format('YYYY-MM-DD')
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    // 初始化type和target
    handleInit () {
      if (this.isFixed) {
        // 固定位
        this.currentType = this.chartInfo.type
        this.searchquery.code = this.chartInfo.target
      } else {
        // 非固定位
        this.currentType = this.chartInfoList[this.rowIndex][this.colIndex].type
        this.searchquery.code = this.chartInfoList[this.rowIndex][this.colIndex].target
      }
    },
    handleSelectType (e) {
      // 相同类型不处理
      if (this.currentType === e.key) {
        return
      }
      this.currentType = e.key
      if (this.isFixed) {
        // 固定位
        const chartInfo = JSON.parse(JSON.stringify(this.chartInfo))
        chartInfo.type = e.key
        this.handleSettingLayout(chartInfo, this.chartInfoList)
      } else {
        // 非固定位
        const chartInfoList = JSON.parse(JSON.stringify(this.chartInfoList))
        chartInfoList[this.rowIndex][this.colIndex].type = e.key
        this.handleSettingLayout(this.chartInfo, chartInfoList)
      }
      this.handleDealData()
    },
    /**
     * 设置布局
     * @params chartInfo {Object} 固定位信息
     * @params chartInfoList {Array} 非固定位信息
     */
    async handleSettingLayout (chartInfo, chartInfoList) {
      const resp = await settingLayout({
        chartInfo,
        chartInfoList
      })
      if (resp.code === 200) {
        // 更新info
        this.$store.dispatch('GetInfo').then(() => {
          this.handleInit()
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.data-rank-container{
  padding: 12px 0 6px 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  .head{
    padding: 0 calc(16em / 14);
    display: flex;
    justify-content: space-between;
    .left{
      display: flex;
      justify-items: flex-start;
      align-items: center;
      .current-type{
        display: flex;
        align-items: center;
        cursor: pointer;
        width: calc(100em / 14);
        margin-right: calc(8em / 14);
        .icon{
          width: calc(14em / 14);
          height: calc(14em / 14);
        }
      }
    }
    .right{
      display: flex;
      // flex-direction: row-reverse;
      .search-item{
        display: flex;
        align-items: flex-start;
        width: calc(133em / 14);
        margin-left: calc(12em / 14);
        &.target{
          width: calc(127em / 14);
        }
      }
    }
  }
  .main{
    flex: 1 auto;
    margin-top: 5px;
  }
}
.active{
  color: @primary-color;
  background-color: fade(@primary-color, 10%);
}
.spin-wrapper {
  height: 100%;
  ::v-deep {
    .ant-spin-container {
      height: 100%;
    }
  }
}

</style>
